<template>
  <SidePopup
    title="Cadastrar produtos similares"
    v-on:close="onClose"
    @submit="onSubmit"
    routeback="/fornecedor/cadastro/produto"
  >
    <section class="containerSearch">
      <div class="wrapperSelect">
        <select class="psmselect" v-model="selected">
          <option disabled value="">Selecione forma de pesquisa</option>
          <option value="nomeProduto">Produto</option>
          <option value="categoria">Categoria</option>
          <option value="codigoBarras">Cod. de barras</option>
          <option value="codigoInterno">Cod. interno</option>
          <option value="codigoAgrupador">Cod. agrupador</option>
        </select>
        <span v-if="selected === ''">Selecione uma opção</span>
      </div>

      <input
        type="text"
        v-model="search"
        :disabled="isLoading"
        placeholder="Pesquisa pelo nome, código interno ou código de barras"
        class="psminput"
      />

      <div class="wrapperButton">
        <FormButton
          :disabled="isLoading"
          :text="isLoading ? 'Aguarde' : 'Buscar'"
          type="auxiliar"
          afterIcon="fas fa-search"
          full
          @click="onSearch"
        />
      </div>
    </section>

    <FormSwitch
      v-model="form.toggle_ignorar_alteracao_erp"
      label="Permitir que a integração modifique os produtos similares"
      val="itens_relacionados"
    />

    <section class="listagemProdutos">
      <h3 class="psmtitle">Listagem de produtos para ser adicionado</h3>
      <span class="psminfo" v-if="produtosPesquisados.length === 0"
        >Nenhum produto em exibição! busque um produto</span
      >
      <div class="wrapperListaProdutos">
        <div v-for="prod in produtosPesquisados" :key="prod.id">
          <div class="containerProdutos">
            <div class="d-flex flex-column align-start">
              <span>{{ prod.nome }}</span>
              <small v-if="prod.id_produto_erp"
                >Cód. interno: {{ prod.id_produto_erp }}</small
              >
              <small v-if="prod.codigobarras"
                >Cód. barras:{{ prod.codigobarras }}</small
              >
            </div>
            <FormButton icon type="perigo" @click="onAddProdutoSimilar(prod)"
              ><v-icon size="18" color="green"
                >fa fa-plus-circle</v-icon
              ></FormButton
            >
          </div>
        </div>
      </div>
    </section>

    <section
      class="containerProdutosAdicionados"
      v-if="produtosAdicionados.length > 0"
    >
      <h3 class="psmtitle">Arraste para ordenar os produtos similares</h3>
      <draggable
        v-model="produtosAdicionados"
        @start="drag = true"
        @end="onEnd"
      >
        <transition-group
          type="transition"
          name="flip-list"
          class="containerListProduct"
        >
          <div
            class="containerSort"
            v-for="item in produtosAdicionados"
            :key="item.id_produto_erp || item.idcadastroextraproduto"
            :id="item.id_produto_erp || item.idcadastroextraproduto"
          >
            <div class="sortable">
              {{ item.nome }}
              <small class="badgeAlert" v-if="item.id_produto_erp"
                >Cód. interno: {{ item.id_produto_erp }}</small
              >
            </div>

            <button
              @click="onRemoveProdutoSimilar(item)"
              class="btnRemoveProduct"
              type="button"
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
          </div>
        </transition-group>
      </draggable>
    </section>

    <template v-slot:buttons>
      <FormButton text="Salvar" :disabled="isLoading" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import FormButton from '@/components/form/FormButton'
import FormSwitch from '@/components/form/FormSwitch'
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import apiWeb from '../../../service/api-web'
import { PRODUTO_LOAD, PRODUTO_UPDATE } from '@/store/actions/produto'
import * as _ from 'lodash'

export default {
  name: 'ProdutoSimilarModal',
  components: { SidePopup, FormButton, FormSwitch, draggable },
  data: () => ({
    form: {},
    produtosAdicionados: [],
    produtosPesquisados: [],
    toggle_ignorar_alteracao_erp: [],
    search: '',
    selected: '',
    currentPage: 0,
    itemsPerPage: 20,
    isLoading: false
  }),
  created() {
    let { idproduto, idcadastroextraproduto } = this.$route.params
    if (idproduto && idcadastroextraproduto) {
      this.getData(idproduto, idcadastroextraproduto)
    }
  },
  computed: {
    ...mapGetters(['getFornecedorId']),
    endpoint() {
      return `/api/v4/products/${this.getFornecedorId}`
    },
    endpointest() {
      return `/api/v4/products/cadastroextra/${this.getFornecedorId}`
    }
  },
  watch: {
    'form.toggle_ignorar_alteracao_erp': {
      deep: true,
      handler(newVal) {
        _.difference(this.form.toggle_ignorar_alteracao_erp, newVal)
        this.form.ignorar_alteracao_erp = newVal
      }
    }
  },
  methods: {
    onSubmit() {
      this.isLoading = true
      const itens_relacionados = this.produtosAdicionados.map(
        item => item.idcadastroextraproduto
      )

      const data = {
        ...this.form,
        itens_relacionados: itens_relacionados
      }

      this.$store
        .dispatch(PRODUTO_UPDATE, data)
        .then(() => {
          this.$vueOnToast.pop(
            'success',
            'Produto Similar atualizado com sucesso'
          )
          this.isLoading = false
          this.onClose(true)
        })
        .catch(() => (this.isLoading = false))
    },
    onClose(routeback = false) {
      this.$emit('close')
      if (routeback) {
        this.$router.replace('/fornecedor/cadastro/produto')
      }
    },
    onSearch() {
      if (this.search && this.selected) {
        this.isLoading = true
        this.produtosPesquisados = []
        const params = `&${this.selected}=${this.search}&tipoProduto=SIMPLES`
        const url = `${this.endpoint}?page=${this.currentPage}&perpage=${this.itemsPerPage}${params}`
        apiWeb.api
          .get(url)
          .then(resp => {
            this.filtraProdutosJaIncluidos(resp.data)
            this.isLoading = false
            if (resp.data.length === 0) {
              this.messageProductNotFound()
            }
          })
          .catch(() => {
            this.isLoading = false
          })
      }
    },
    filtraProdutosJaIncluidos(produtos) {
      if (this.produtosAdicionados.length > 0) {
        const idsProdutos = this.produtosAdicionados.map(
          p => p.idcadastroextraproduto
        )

        this.produtosPesquisados = produtos.filter(
          p => !idsProdutos.includes(p.idcadastroextraproduto)
        )
      } else {
        this.produtosPesquisados = produtos
      }
    },
    messageProductNotFound() {
      this.$vueOnToast.pop('warning', 'Nenhum produto encontrado!')
    },
    onAddProdutoSimilar(produto) {
      this.produtosPesquisados = this.produtosPesquisados.filter(
        p => p.idcadastroextraproduto !== produto.idcadastroextraproduto
      )
      this.produtosAdicionados = [...this.produtosAdicionados, produto]
    },
    onRemoveProdutoSimilar(produto) {
      const options = {
        text: 'Essa ação é irreversível.',
        title: `Você confirma a exclusão do produto\n'${produto.nome}'?`
      }
      this.confirmSwal(options, () => {
        this.produtosAdicionados = this.produtosAdicionados.filter(
          prod => prod.idcadastroextraproduto !== produto.idcadastroextraproduto
        )
      })
    },
    onEnd() {
      this.isLoading = false
    },
    getData(idproduto, idcadastroextraproduto) {
      this.isLoading = true
      this.$store
        .dispatch(PRODUTO_LOAD, { idproduto, idcadastroextraproduto })
        .then(produto => {
          this.form = {
            ...this.form,
            ...produto,
            setores: (produto.setores || []).map(item => item.idsetor),
            toggle_ignorar_alteracao_erp: produto.ignorar_alteracao_erp
          }
          this.isLoading = false
          this.carregaProdutoSimilares(this.form.itens_relacionados)
        })
        .catch(() => {
          this.isLoading = false
          this.onClose()
        })
    },
    carregaProdutoSimilares(produtosRelacionados) {
      this.loadProdut(produtosRelacionados)
    },
    loadProdut(produtosRelacionados) {
      const url = `${this.endpointest}`
      const params = { ids: produtosRelacionados }

      apiWeb.api
        .post(url, params)
        .then(resp => {
          this.produtosAdicionados = resp.data.sort(this.sortProdutoSimilares)
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    sortProdutoSimilares(a, b) {
      const ids = this.form.itens_relacionados
      return (
        ids.indexOf(a.idcadastroextraproduto) -
        ids.indexOf(b.idcadastroextraproduto)
      )
    }
  }
}
</script>

<style lang="scss">
.seletor {
  margin: 0px !important;
  .v-input__slot {
    margin: 0px !important;
  }
  .v-messages {
    display: none !important;
  }
}

.containerListProduct {
  display: flex;
  flex-direction: column;
  gap: 8px;

  margin-top: 10px;
}

.containerSort {
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 20px 8px #d0d0d0;
  overflow: hidden;
  background: #f2f2f2;
  border-radius: 8px;

  .sortable {
    width: 100%;
    background: white;
    padding: 1em;
    border-bottom: 1px solid;
    cursor: move;
  }
}

.btnRemoveProduct {
  width: 70px;
  background: #ffdada;
  color: var(--danger-color);
  border-radius: 8px;
  border-bottom: 1px solid #ff0000;
}

.flip-list-move {
  transition: transform 0.5s;
}

.containerSearch {
  display: flex;
  gap: 30px;

  .wrapperButton {
    width: 200px;
  }
}

.containerProdutos {
  display: flex;
  align-items: center;
}
.psmselect {
  padding: 0.5rem 1rem;
  border: 1px solid #121214;
  border-radius: 0.875rem;
  outline: none;

  &:focus {
    border-color: var(--primary-color);
  }
}
.psminput {
  display: 'flex';
  border: 1px solid #121214;
  width: 100%;
  height: 42px;
  max-width: 450px;
  padding: 0.5rem 1rem;
  border-radius: 0.875rem;
  box-sizing: border-box;
  align-items: baseline;
  outline: none;

  &:focus {
    border-color: var(--primary-color);
  }
}

.containerProdutosAdicionados {
  margin-top: 2rem;
}

.listagemProdutos {
  margin-top: 0.5rem;

  h3 {
    margin-bottom: 1rem;
  }
}

.wrapperSelect {
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 0.75rem;
    font-weight: 700;
    color: var(--danger-color);
  }
}

.wrapperListaProdutos {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.psmtitle {
  color: var(--primary-color);
}

.psminfo {
  font-weight: 700;
  font-size: 0.75rem;
  color: var(--danger-color);
}

.badgeAlert {
  background: var(--alert-color);
  color: white;
  font-weight: 700;
  border-radius: 5px;
  padding: 3px;
}
</style>
